import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

// markup
const NotFoundPage = (location) => {
  return (
    <Layout location={location}>
      <h3>Seite nicht gefunden</h3>
      <p>Die angeforderte Seite existiert nicht.</p>
      <Link to="/">zur Startseite</Link>
    </Layout>
  );
};

export default NotFoundPage;
